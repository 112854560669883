<template>
  <div>
    <a-button type="primary" @click="visible = true">高级搜索</a-button>
    <a-modal
      :visible="visible"
      title="高级搜索"
      @cancel="cancel"
      :footer="null"
      width="1000px"
    >
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-row :gutter="[8, 8]">
          <a-col :span="24">
            <div class="title">基本信息</div>
          </a-col>
          <a-col :span="24">
            <a-form-item>
              <a-select
                mode="multiple"
                :filter-option="$selectFilterOption"
                show-search
                placeholder="员工标签"
                v-decorator="['tagIdList']"
                style="width: 100%;"
              >
                <a-select-option
                  v-for="item in tagArr"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="姓名" v-decorator="['name']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select placeholder="性别" v-decorator="['sex']">
                <a-select-option
                  v-for="item in sex"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select
                v-decorator="['marryType']"
                placeholder="婚姻状况"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in marrigeStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-select
                v-decorator="['childrenType']"
                placeholder="子女状况"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in childStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-date-picker
                v-decorator="['birthday']"
                placeholder="出生日期"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最小年龄"
                  v-decorator="['ageMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最大年龄"
                  v-decorator="['ageMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最矮身高"
                  v-decorator="['heightMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最高身高"
                  v-decorator="['heightMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最小体重"
                  v-decorator="['weightMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最大体重"
                  v-decorator="['weightMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select
                v-decorator="['bloodType']"
                placeholder="血型"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in bloodTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="专业特长" v-decorator="['expertises']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="兴趣爱好" v-decorator="['interests']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="手机号" v-decorator="['mobile']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="办公电话" v-decorator="['phone']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="公司邮箱" v-decorator="['email']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input
                placeholder="个人邮箱"
                v-decorator="['emailIndividual']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="国籍" v-decorator="['nationality']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="籍贯" v-decorator="['nativePlace']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="民族" v-decorator="['ethnicGroup']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select placeholder="政治面貌" v-decorator="['political']">
                <a-select-option
                  v-for="item in politicalOutlookList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-date-picker
                style="width: 100%"
                placeholder="入党(团)日期"
                v-decorator="['politicalDate']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="">
              <a-select placeholder="党员" v-decorator="['partyMember']">
                <a-select-option
                  v-for="item in partyList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="社会职务" v-decorator="['socialPost']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-date-picker
                v-decorator="['workStartTime']"
                placeholder="参加工作日期"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最小社会工龄"
                  v-decorator="['socialAgeMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最大社会工龄"
                  v-decorator="['socialAgeMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-date-picker
                v-decorator="['entryDate']"
                placeholder="入职日期"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最小司龄"
                  v-decorator="['servingAgeMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="最大司龄"
                  v-decorator="['servingAgeMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select
                v-decorator="['deformityFlag']"
                placeholder="残疾标识"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in disabilitySign"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <div class="title">额外信息</div>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select
                v-decorator="['credentialType']"
                placeholder="证件类型"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in credentialType"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input
                placeholder="证件号码"
                v-decorator="['credentialNumber']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select
                v-decorator="['education']"
                placeholder="最高学历"
                style="width: 100%"
              >
                <a-select-option
                  v-for="item in degreeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="毕业院校" v-decorator="['school']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="专业" v-decorator="['major']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="语言类型" v-decorator="['languageType']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="语言水平" v-decorator="['languageLevel']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input v-decorator="['certType']" placeholder="证书类型" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="证书名称" v-decorator="['certName']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="证书编号" v-decorator="['certNumber']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="荣誉类型" v-decorator="['honorType']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="所属荣誉" v-decorator="['honorName']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="荣誉等级" v-decorator="['honorRank']" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <div class="title">职位信息</div>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select placeholder="员工类别" v-decorator="['type', {}]">
                <a-select-option
                  v-for="item in employeeType"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-select placeholder="员工状态" v-decorator="['status']">
                <a-select-option
                  v-for="item in employeeStatus"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-select
                show-search
                :filter-option="$selectFilterOption"
                placeholder="部门"
                v-decorator="['belongDept']"
              >
                <a-select-option
                  v-for="item in organizationList"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.uniqueName }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="职位" v-decorator="['position']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="职级" v-decorator="['rank']" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="职等" v-decorator="['grade']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="职称" v-decorator="['skillName']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="职称等级" v-decorator="['skillRank']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input
                placeholder="后备人才标识"
                v-decorator="['reserveTalentFlag']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <div class="title">人才</div>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input placeholder="考核年度" v-decorator="['appraiseYear']" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item>
              <a-input
                placeholder="考核结果"
                v-decorator="['appraiseResult']"
              />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="沟通能力"
                  v-decorator="['abilityCommunicateMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="沟通能力"
                  v-decorator="['abilityCommunicateMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="学习能力"
                  v-decorator="['abilityLearnMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="学习能力"
                  v-decorator="['abilityLearnMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="管理能力"
                  v-decorator="['abilityManageMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="管理能力"
                  v-decorator="['abilityManageMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="销售能力"
                  v-decorator="['abilitySaleMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="销售能力"
                  v-decorator="['abilitySaleMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="技术能力"
                  v-decorator="['abilitySkillMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="技术能力"
                  v-decorator="['abilitySkillMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="人才发展积分"
                  v-decorator="['scoreDevelopMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="人才发展积分"
                  v-decorator="['scoreDevelopMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="360评价得分"
                  v-decorator="['result360Min']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="360评价得分"
                  v-decorator="['result360Max']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="第三方评测得分"
                  v-decorator="['evaluation3rdMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="第三方评测得分"
                  v-decorator="['evaluation3rdMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="综合能力"
                  v-decorator="['comprehensiveMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="综合能力"
                  v-decorator="['comprehensiveMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item style="margin-bottom: 0">
              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="文化匹配度"
                  v-decorator="['cultureMin']"
                  style="width: 100%"
                />
              </a-form-item>

              <span
                style="width: 10%; display: inline-block; text-align: center"
                >-</span
              >

              <a-form-item style="width: 45%; display: inline-block">
                <a-input-number
                  placeholder="文化匹配度"
                  v-decorator="['cultureMax']"
                  style="width: 100%"
                />
              </a-form-item>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">搜索</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>


<script>
import organization from "@/mixins/organization";
import { mapGetters } from "vuex";
import { fetchList as fetchTags} from "@/api/human-resources/tag";
export default {
  mixins: [organization],

  props: {
    searchValues: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      tagArr: [],
    };
  },

  watch: {
    visible(newValue) {
      if (newValue) {
        console.log(Object.keys(this.searchValues));
        if (Object.keys(this.searchValues).length === 0) {
          this.form.resetFields();
        }
        fetchTags({
            pageNum: 1,
            pageSize: 9999,
        })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.tagArr = Object.freeze(res.list);
          }
        })
      }
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    lists() {
      return this.findDataDict("talentMap");
    },
    // 员工类别
    employeeType() {
      return (
        this.lists.find((item) => item.value === "employeeType").children || []
      );
    },
    // 婚姻状况
    marrigeStatus() {
      return (
        this.lists.find((item) => item.value === "marrigeStatus").children || []
      );
    },
    // 子女状况
    childStatus() {
      return (
        this.lists.find((item) => item.value === "childStatus").children || []
      );
    },
    // 残疾标识
    disabilitySign() {
      return (
        this.lists.find((item) => item.value === "disabilitySign").children ||
        []
      );
    },
    // 证件类型
    credentialType() {
      return (
        this.lists.find((item) => item.value === "credentialType").children ||
        []
      );
    },
    // 人员类型
    personType() {
      return (
        this.lists.find((item) => item.value === "personType").children || []
      );
    },
    // 关系类型
    relationshipType() {
      return (
        this.lists.find((item) => item.value === "relationshipType").children ||
        []
      );
    },
    // 毕业方式
    graduationWay() {
      return (
        this.lists.find((item) => item.value === "graduationWay").children || []
      );
    },
    // 职称等级
    professionalTitle() {
      return (
        this.lists.find((item) => item.value === "professionalTitle")
          .children || []
      );
    },
    // 荣誉标识
    honorLogo() {
      return (
        this.lists.find((item) => item.value === "honorLogo").children || []
      );
    },
    // 荣誉等级
    honorLevel() {
      return (
        this.lists.find((item) => item.value === "honorLevel").children || []
      );
    },
    // 标识
    logo() {
      return this.lists.find((item) => item.value === "logo").children || [];
    },
    // 异动类型
    changeType() {
      return (
        this.lists.find((item) => item.value === "changeType").children || []
      );
    },
    // 后备人才标识
    reserveTalentLogo() {
      return (
        this.lists.find((item) => item.value === "reserveTalentLogo")
          .children || []
      );
    },
    // 培训类型
    trainType() {
      return (
        this.lists.find((item) => item.value === "trainType").children || []
      );
    },

    sex() {
      return this.findDataDict("sex");
    },
    // 政治面貌
    politicalOutlookList() {
      return this.findDataDict("politicalOutlook");
    },

    // 血型
    bloodTypeList() {
      return this.findDataDict("bloodType");
    },
    partyList() {
      return this.findDataDict("partyMemberType");
    },
    employeeStatus() {
      return this.findDataDict("employeeStatus");
    },

    // 学历
    degreeList() {
      return this.findDataDict("degree");
    },
  },


  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          this.$emit("search", {
            ...values,
            birthday: values.birthday
              ? values.birthday.format("YYYY-MM-DD")
              : undefined,
            politicalDate: values.politicalDate
              ? values.politicalDate.format("YYYY-MM-DD")
              : undefined,
            workStartTime: values.workStartTime
              ? values.workStartTime.format("YYYY-MM-DD")
              : undefined,
            entryDate: values.entryDate
              ? values.entryDate.format("YYYY-MM-DD")
              : undefined,
          });
          this.cancel();
        }
      });
    },

    cancel() {
      this.visible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-weight: bold;
}
</style>