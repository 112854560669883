<template>
  <span>
    <input ref="upload" style="display: none" type="file" @change="onChange" />

    <a-button shape="round" :loading="loading" @click="onClick"
      >批量导入</a-button
    >
  </span>
</template>

<script>
import { upload } from "@/api/human-resources/employee";
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onClick() {
      this.$refs.upload.click();
    },

    onChange(e) {
      const files = e.target.files;
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append("file", files[i]);
      }

      this.loading = true;

      upload(data)
        .then(() => {
          this.$emit("refresh");
        })
        .finally(() => {
          this.loading = false;
          e.target.value = null;
        });
    },
  },
};
</script>




