<template>
  <div class="wrapper">
    <div class="cards">
      <TransitionGroup name="list">
        <div
          class="card"
          v-for="(item, index) in list"
          :key="item.id"
          @click="next(index)"
        >
          <div class="flex user-info">
            <a class="avatar" :href="item.header" target="_blank">
              <img :src="item.header" alt="" title="点击查看大图" />
            </a>
            <div>
              <div class="name">
                <span style="margin-right: 8px">{{ item.name }}</span>
                <div class="tags">
                  <Tags :detail="item" />
                </div>
              </div>
              <div class="position">
                <span style="padding-left: 32px">
                  {{ item.dept }}
                </span>
              </div>
            </div>
          </div>

          <div class="content">
            <div class="left" v-if="item.mobile">
              <img class="item-icon" :src="iconMobile" alt="" />
              <span>{{ item.mobile }}</span>
            </div>
            <div
              class="left"
              v-if="
                typeof item.position === 'string' && item.position.length > 0
              "
            >
              <img class="item-icon" :src="iconJob" alt="" />
              <span>{{ item.position }}</span>
            </div>

            <div class="left" v-if="item.position && item.position.belongDept">
              <img class="item-icon" :src="iconJob" alt="" />
              <span>{{
                organizationList.find(
                  (item) => item.id === item.position.belongDept
                ).uniqueName
              }}</span>
            </div>
            <div class="left" v-if="item.position && item.position.position">
              <img class="item-icon" :src="iconLocation" alt="" />
              <span>{{ item.position.position }}</span>
            </div>

            <div class="footer">
              <a-space size="large">
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  style="
                    background: rgba(24, 144, 255, 0.1);
                    border-color: #1890ff;
                    color: #1890ff;
                    width: 100px;
                  "
                  v-if="$getPermission($route.path + '/edit')"
                  @click.prevent="
                    $router.push($route.path + '/edit?id=' + item.id)
                  "
                >
                  <img class="icon" :src="iconEdit" alt="" />
                  编辑
                </a-button>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  style="width: 100px"
                  v-if="$getPermission($route.path + '/detail')"
                  @click.prevent="
                    $router.push($route.path + '/detail?id=' + item.id)
                  "
                >
                  <img class="icon" :src="iconResume" alt="" />
                  简历</a-button
                >
              </a-space>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import request from "@/api/request";
import Tags from "./tags.vue";
export default {
  props: {
    organizationList: {
      type: Array,
      default: () => [],
    },
    searchResult: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Tags,
  },
  data() {
    return {
      list: [],
      nextList: [],

      iconMobile: require("@/assets/employee/icons/mobile.png"),
      iconJob: require("@/assets/employee/icons/job.png"),
      iconLocation: require("@/assets/employee/icons/location.png"),
      iconEdit: require("@/assets/employee/icons/edit.png"),
      iconResume: require("@/assets/employee/icons/resume.png"),
    };
  },

  watch: {
    searchResult() {
      if (this.searchResult.length) {
        this.list.unshift(...this.searchResult);
      }
    },
  },
  mounted() {
    request({
      url: "/user-service/talentMap/randomList",
      method: "post",
    }).then((res) => {
      if (res && Array.isArray(res.list)) {
        this.list = res.list.filter((item, index) => index < 5);
      }
    });

    this.getNext();
  },
  methods: {
    getNext() {
      request({
        url: "/user-service/talentMap/randomList",
        method: "post",
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.nextList.push(...res.list);
        }
      });
    },
    next(index) {
      if (index !== 0) {
        this.list.shift();
        const item = this.nextList.shift();
        this.list.push(item);

        if (this.nextList.length < 2) {
          this.getNext();
        }
      }
    },
  },
};
</script>


<style lang="less" scoped>
.wrapper {
  overflow: hidden;
}
.cards {
  position: relative;
  width: 600px;
  height: 600px;
  margin: 80px auto;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-32px) translateY(-12px);
}

.list-leave-active {
  position: absolute;
}

.card {
  position: absolute;
  top: 2000px;
  bottom: 2000px;
  right: -2000px;
  width: 500px;
  height: 520px;

  background-color: #fff;
  background-image: url(https://s.upapi.cn/2022/11/18/ca8ce76d2622149969097e0a2d2bb4f4/employee-bg.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  line-height: 2em;
  color: #f0f0f0;
  transition: all 0.3s ease;
  cursor: pointer;

  display: flex;
  flex-direction: column;
}
.card:first-child {
  z-index: 10;
  color: #222;
  // top: -45px;
  // bottom: 45px;
  // right: 90px;
  top: 0;
  bottom: 0;
  right: 80px;
}
// .card:nth-child(2) {
//   z-index: 9;
//   top: -40px;
//   bottom: 40px;
//   right: 80px;
// }
// .card:nth-child(3) {
//   z-index: 8;
//   top: -35px;
//   bottom: 35px;
//   right: 70px;
// }
// .card:nth-child(4) {
//   z-index: 7;
//   top: -30px;
//   bottom: 30px;
//   right: 60px;
// }
// .card:nth-child(5) {
//   z-index: 6;
//   top: -25px;
//   bottom: 25px;
//   right: 50px;
// }
// .card:nth-child(6) {
//   z-index: 5;
//   top: -20px;
//   bottom: 20px;
//   right: 40px;
// }
.card:nth-child(2) {
  z-index: 4;
  top: 0;
  bottom: 0;

  right: 60px;
}
.card:nth-child(3) {
  z-index: 3;
  top: 0;
  bottom: 0;

  right: 40px;
}
.card:nth-child(4) {
  z-index: 2;
  top: 0;
  bottom: 0;

  right: 20px;
}
.card:nth-child(5) {
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
}

.user-info {
  position: relative;
  padding: 32px;

  .avatar {
    img {
      display: block;
      border-radius: 50%;
      object-fit: cover;
      width: 88px;
      height: 88px;
      margin-right: 16px;
    }
  }

  .name {
    font-weight: bold;
    font-size: 20px;
    padding-left: 8px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
  }
  .position {
    position: absolute;
    background-color: #f3f3f3;
    left: 0;
    top: 68px;
    right: 0;
    bottom: 0;
    z-index: -1;
    padding-left: 112px;
    padding-top: 16px;
  }
  .extra {
    position: absolute;
    top: 16px;
    right: 16px;
    gap: 8px;
  }
}

.content {
  padding: 32px;
  color: #999;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.item-icon {
  height: 32px;
  margin-right: 8px;
}
.icon {
  height: 12px;
  margin-right: 8px;
  position: relative;
  margin-top: -2px;
}

.footer {
  margin-top: auto;
}
</style>