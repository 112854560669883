import request from '../request'


export function fetchList(data) {
    return request({
        url: '/user-service/employee/tag/list',
        method: 'post',
        data
    })
}

export function remove(id) {
    return request({
        url: '/user-service/employee/tag/del/' + id,
        method: 'post',
    })
}

export function add(data) {
    return request({
        url: '/user-service/employee/tag/add',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: '/user-service/employee/tag/update',
        method: 'post',
        data
    })
}